var LANG_TARGET = JSON.parse(window.localStorage.getItem("lang")) || "ENG";
var angular_loaded_once = 0;
var app;
(function (app) {
    var core;
    (function (core) {
        'use strict';
        appRun.$inject = ["appRunConfig", "cacheImages", "mylocalStore", "$rootScope", "$state"];
        configure.$inject = ["$stateProvider", "$locationProvider", "$urlRouterProvider", "$qProvider", "$httpProvider"];
        angular.module('app.core', []);
        angular
            .module('app.core')
            .config(configure)
            .run(appRun)
            .constant('_', window._)
            .constant('myStorage', window.localStorage)
            .constant('Rx', window.Rx || undefined)
            .filter('trustAsHtml', ['$sce', function ($sce) {
                return function (text) {
                    return $sce.trustAsHtml(text);
                };
            }])
            .filter('range', function () {
            return function (input, total) {
                total = parseInt(total);
                for (var i = 0; i < total; i++)
                    input.push(i);
                return input;
            };
        });
        /* @ngInject */
        function appRun(appRunConfig, cacheImages, mylocalStore, $rootScope, $state) {
            // 0.
            ////////////////////////////////////
            /// SET PROJECT CONFIG!!!!
            appRunConfig.init({
                mylocalStore: mylocalStore,
                setTitleLang: setTitleLang,
                cacheImages: cacheImages
                // callback
            }, function (rootScopeProps) {
                ///////////////////////////////////////////////////
                //////////////////////////////////////////
                //// run app dependables
                // run all else on once this is done
                if (rootScopeProps === void 0) { rootScopeProps = null; }
                //////////////////////////
                appRunConfig.initDataServices();
                ////////////////////
                /////////////////////////////////////////////
                var menuItems = appRunConfig.generateMenu(getStates);
                $rootScope.pages = menuItems;
                // 1.
                //////////////////////////////////////////////////////
                appRunConfig.stateChangeStart($state);
                ////////////////////////////////////////////////////////
                // 2.
                ////////////////////////////////////////////////////////
                appRunConfig.stateChangeSuccess();
                ////////////////////////////////////////////////////////
                ///////////////////////////////////////////////////
                ////////////////////////////////////////// 
                console.log('what are the rootScopeProps', rootScopeProps);
            }); // appRunConfig end
            ///////////////////////
        }
        /* @ngInject */
        function configure($stateProvider, $locationProvider, $urlRouterProvider, $qProvider, $httpProvider) {
            //$translateProvider.useSanitizeValueStrategy('sanitizeParameters');
            $qProvider.errorOnUnhandledRejections(false);
            $httpProvider.defaults.headers.post['Accept'] = 'application/json, text/javascript';
            $httpProvider.defaults.headers.post['Content-Type'] = 'multipart/form-data; charset=utf-8';
            $locationProvider.html5Mode(true);
            $urlRouterProvider.otherwise('/app');
            var states = getStates();
            states.forEach(function (state) {
                console.log('state> ', state.state);
                $stateProvider.state(state.state, state.config);
            });
        }
        /**
         *
         * title languages are set based on cache variable
         * and called from getStates
         */
        function setTitleLang(title_name) {
            var get_lang;
            if (LANG_TARGET == "ENG") {
                get_lang = 0;
            }
            if (LANG_TARGET == "TH") {
                get_lang = 1;
            }
            var titles = {
                home: ['Home', "Home"],
                about: ['About Us', "เกี่ยวกับเรา"],
                timeline: ['Timeline', 'ความเป็นมา'],
                media: ['Media', 'สื่อ'],
                career: ["Career", "ร่วมงานกับเรา"],
                blog: ["Blog", "บล็อก"],
                contacts: ['Contacts', 'ติดต่อเรา'],
            };
            return titles[title_name][get_lang] || titles[title_name][0];
        }
        function getStates() {
            /* @ngInject */
            resolver.$inject = ["rxData"];
            function resolver(rxData) {
                return {
                    /* @ngInject */
                    dataFeed: ["rxData", "$q", function (rxData, $q) {
                        return rxData.currentPageData();
                    }]
                };
            }
            return [
                {
                    state: 'home',
                    name: 'home',
                    config: {
                        default: true,
                        url: '/app',
                        template: '<home ng-cloak></home>',
                        title: setTitleLang('home'),
                        // doesnt work
                        //resolve:resolver(rxData),
                        settings: {
                            nav: 1
                        },
                    }
                },
                {
                    state: 'about',
                    name: 'about',
                    config: {
                        url: '/app/about',
                        template: '<about ng-cloak></about>',
                        title: setTitleLang('about'),
                        settings: {
                            nav: 1
                        },
                    }
                },
                {
                    state: 'timeline',
                    config: {
                        url: '/app/timeline',
                        template: '<timeline ng-cloak></timeline>',
                        title: setTitleLang('timeline'),
                        settings: {
                            nav: 1
                        },
                    }
                },
                {
                    state: 'media',
                    config: {
                        rel: 'gallery',
                        url: '/app/media',
                        template: '<media ng-cloak></media>',
                        title: setTitleLang('media'),
                        settings: {
                            nav: 1
                        },
                    }
                },
                {
                    state: 'gallery',
                    config: {
                        master: true,
                        url: '/app/media-gallery/:gallery',
                        template: '<gallery ng-cloak></gallery>',
                        title: 'Media Gallery',
                        settings: {
                            nav: 1
                        },
                    }
                },
                {
                    state: 'contacts',
                    config: {
                        url: '/app/contacts',
                        template: '<contacts ng-cloak></contacts>',
                        title: setTitleLang('contacts'),
                        settings: {
                            nav: 1
                        },
                    }
                },
                {
                    state: 'career',
                    config: {
                        url: '/app/career',
                        template: '<career ng-cloak></career>',
                        title: setTitleLang('career'),
                        settings: {
                            nav: 1
                        },
                    }
                }
            ];
        }
    })(core = app.core || (app.core = {}));
})(app || (app = {}));
